body .whirl:before {
  content: attr(data-spinner-text);
  display: block;
  background-color: #f0f0f0;
  opacity: 0.7;
}

.whirl {
  &:after {
    content: "";
    position: absolute;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 20;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #999;
    opacity: 0.6;
  }
}

body .whirl:after {
  border-top-color: #47b372;
}

.whirl {
  &:after {
    z-index: 2;
    height: 40px;
    width: 40px;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px;
    transition: all 0.75s ease 0s;
    border-radius: 100%;
    border-top: 4px solid #555;
    -webkit-animation: standard 0.75s linear infinite;
    animation: standard 0.75s linear infinite;
    transform-origin: center;
    content: "";
    position: absolute;
  }

  &:before {
    content: "";
    position: absolute;
  }
}

@-webkit-keyframes standard {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes standard {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
